import { request } from '../methods'

export default {
  list: request('get', '/api/v1/agency/'),
  create: request('post', '/api/v1/agency/'),
  archive: request('delete', '/api/v1/agency/{id}/'),
  rename: request('put', '/api/v1/agency/{id}/rename/'),
  listMembers: request('get', '/api/v1/agency/{id}/members/'),
  createMember: request('post', '/api/v1/agency/{id}/members/'),
  updateMember: request('put', '/api/v1/agency/{id}/members/{membership_pk}/'),
  deleteMember: request('delete', '/api/v1/agency/{id}/members/{membership_pk}/'),
  updateDenylist: request('post', '/api/v1/agency/{id}/members/{membership_pk}/denylist/'),
  setProgramsAPM: request('post', '/api/v1/agency/{agency_id}/apm/set_programs/'),
}
