import {
  LoginRequest,
  LoginResponse,
  LogoutRequest,
  PatrickServiceClient,
  ValidateUserRequest,
  ValidateUserResponse,
  UpdateAccountRequest,
  UpdateAccountResponse,
  ChangePasswordRequest,
  ChangePasswordResponse,
  ChallengeDeviceRequest,
  ChallengeDeviceResponse,
  DeleteDeviceRequest,
  DeleteDeviceResponse,
  ProvisionDeviceRequest,
  ProvisionDeviceResponse,
  RegisterDeviceRequest,
  RegisterDeviceResponse,
  UpdateDeviceRequest,
  UpdateDeviceResponse,
} from '@policyfly/protobuf'

import { api } from '@/api'
import { devtools } from '@/plugins/devtools/api'

import type { ApiVariableEndpoints, CreateEndpointParams } from '@/stores/api'

export interface UserApiEndpoints {
  /**
   * Validates that a user has an active account.
   *
   * @returns Information about available 2FA devices for this account.
   */
  validateUser: (params: ValidateUserRequest) => Promise<ValidateUserResponse>
  /**
   * Authenticates a user with the provided login information.
   * Will update the stored API tokens for for any future requests.
   */
  login: (params: LoginRequest) => Promise<void>
  /**
   * Sets up the user account for the first time and logs them in.
   * Will update the stored API tokens for for any future requests.
   */
  setupAccount: (params: { email: string, password: string, token: string }) => Promise<{ id: number }>
  /**
   * Tries to log the user out of the application.
   * Errors are simply ignored and not thrown.
   *
   * Tokens will be cleared from storage regardless of the result.
   */
  logout: () => Promise<void>
  /**
   * Updates the user's account information.
   */
  updateAccount: (request: UpdateAccountRequest) => Promise<UpdateAccountResponse>
  /**
   * Updates a user's password.
   */
  changePassword: (request: ChangePasswordRequest) => Promise<ChangePasswordResponse>
  /**
   * @see {@link PatrickServiceClient.challengeDevice challengeDevice}.
   */
  challengeDevice: (request: ChallengeDeviceRequest) => Promise<ChallengeDeviceResponse>
  /**
   * @see {@link PatrickServiceClient.deleteDevice deleteDevice}.
   */
  deleteDevice: (params: DeleteDeviceRequest) => Promise<DeleteDeviceResponse>
  /**
   * @see {@link PatrickServiceClient.provisionDevice provisionDevice}.
   */
  provisionDevice: (params: ProvisionDeviceRequest) => Promise<ProvisionDeviceResponse>
  /**
   * @see {@link PatrickServiceClient.registerDevice registerDevice}.
   */
  registerDevice: (params: RegisterDeviceRequest) => Promise<RegisterDeviceResponse>
  /**
   * @see {@link PatrickServiceClient.updateDevice updateDevice}.
   */
  updateDevice: (params: UpdateDeviceRequest) => Promise<UpdateDeviceResponse>
}

/**
 * Creates endpoints related to the Application workflow.
 */
export const createUserEndpoints = (params: CreateEndpointParams): ApiVariableEndpoints<UserApiEndpoints> => {
  const patrickServiceClientAuth = new PatrickServiceClient(params.transport)
  const patrickServiceClientAnon = new PatrickServiceClient(params.transportAnonymous)

  const validateUser: UserApiEndpoints['validateUser'] = async ({ email, password }) => {
    const request = ValidateUserRequest.create({ email, password })
    const { response } = await patrickServiceClientAnon.validateUser(request)

    devtools.logGrpc({
      description: 'Validate User',
      messages: [
        { type: ValidateUserRequest, key: 'request', message: request },
        { type: ValidateUserResponse, key: 'response', message: response },
      ],
    })

    return response
  }

  function loadTokens (response: LoginResponse): void {
    params.refreshToken.value = response.jwt?.refresh ?? ''
    params.accessToken.value = response.jwt?.access ?? ''

    const expirationDate = new Date()
    expirationDate.setHours(expirationDate.getHours() + response.sessionLength! / 60)
    params.spaToken.value = response.spaToken
    params.spaTokenExpiration.value = expirationDate.toISOString()
  }

  const login: UserApiEndpoints['login'] = async ({ email, password, otpToken, rememberMe }) => {
    const request = LoginRequest.create({ email, password, otpToken, rememberMe })
    const { response } = await patrickServiceClientAnon.login(request)

    devtools.logGrpc({
      description: 'Login',
      messages: [
        { type: LoginRequest, key: 'request', message: request },
        { type: LoginResponse, key: 'response', message: response },
      ],
    })

    if (!response.tokenValidated) throw new Error('Invalid token.')

    loadTokens(response)
  }

  const setupAccount: UserApiEndpoints['setupAccount'] = async ({ email, password, token }) => {
    const res = await api.users.setupAccount({ body: { email, password, otp_token: token } })

    loadTokens(LoginResponse.create({
      // @ts-expect-error: Swagger definitions do not exist on the api endpoints
      jwt: res.data.jwt,
      sessionLength: res.data.session_length,
      spaToken: res.data.spa_token,
      tokenValidated: true,
    }))

    return {
      id: res.data.user.id,
    }
  }

  const logout: UserApiEndpoints['logout'] = async () => {
    try {
      await patrickServiceClientAuth.logout(LogoutRequest.create())
    } catch {
      // ignore errors
    } finally {
      params.logout()
    }
  }

  const updateAccount: UserApiEndpoints['updateAccount'] = async (request) => {
    const { response } = await patrickServiceClientAuth.updateAccount(request)

    devtools.logGrpc({
      description: 'Update Account',
      messages: [
        { type: UpdateAccountRequest, key: 'request', message: request },
        { type: UpdateAccountResponse, key: 'response', message: response },
      ],
    })

    return response
  }

  const changePassword: UserApiEndpoints['changePassword'] = async (request) => {
    const { response } = await patrickServiceClientAuth.changePassword(request)

    devtools.logGrpc({
      description: 'Change Password',
      messages: [
        { type: ChangePasswordRequest, key: 'request', message: request },
        { type: ChangePasswordResponse, key: 'response', message: response },
      ],
    })

    return response
  }

  const challengeDevice: UserApiEndpoints['challengeDevice'] = async (request) => {
    const { response } = await patrickServiceClientAnon.challengeDevice(request)

    devtools.logGrpc({
      description: 'Challenge Device',
      messages: [
        { type: ChallengeDeviceRequest, key: 'request', message: request },
        { type: ChallengeDeviceResponse, key: 'response', message: response },
      ],
    })

    return response
  }

  const deleteDevice: UserApiEndpoints['deleteDevice'] = async (request) => {
    const { response } = await patrickServiceClientAuth.deleteDevice(request)

    devtools.logGrpc({
      description: 'Delete Device',
      messages: [
        { type: DeleteDeviceRequest, key: 'request', message: request },
        { type: DeleteDeviceResponse, key: 'response', message: response },
      ],
    })

    return response
  }

  const provisionDevice: UserApiEndpoints['provisionDevice'] = async (request) => {
    const { response } = await patrickServiceClientAuth.provisionDevice(request)

    devtools.logGrpc({
      description: 'Provision Device',
      messages: [
        { type: ProvisionDeviceRequest, key: 'request', message: request },
        { type: ProvisionDeviceResponse, key: 'response', message: response },
      ],
    })

    return response
  }

  const registerDevice: UserApiEndpoints['registerDevice'] = async (request) => {
    const { response } = await patrickServiceClientAuth.registerDevice(request)

    devtools.logGrpc({
      description: 'Register Device',
      messages: [
        { type: RegisterDeviceRequest, key: 'request', message: request },
        { type: RegisterDeviceResponse, key: 'response', message: response },
      ],
    })

    return response
  }

  const updateDevice: UserApiEndpoints['updateDevice'] = async (request) => {
    const { response } = await patrickServiceClientAuth.updateDevice(request)

    devtools.logGrpc({
      description: 'Update Device',
      messages: [
        { type: UpdateDeviceRequest, key: 'request', message: request },
        { type: UpdateDeviceResponse, key: 'response', message: response },
      ],
    })

    return response
  }

  const endpoints: UserApiEndpoints = {
    validateUser,
    login,
    setupAccount,
    logout,
    updateAccount,
    changePassword,
    challengeDevice,
    deleteDevice,
    provisionDevice,
    registerDevice,
    updateDevice,
  }
  return {
    django: endpoints,
    grpc: endpoints,
  }
}
