import {
  DeleteDeviceRequest,
  MultipartFormFile,
  ProvisionDeviceRequest,
  UpdateAccountRequest,
  UpdateDeviceRequest,
} from '@policyfly/protobuf'
import { defineStore } from 'pinia'

import { useApiStore } from '@/stores/api'
import { useAuthenticationStore } from '@/stores/authentication'

import { api } from '@/api'
import { hotReloadStore } from '@/utils/build'

import type { ProvisionDeviceResponse_Totp } from '@policyfly/protobuf'
import type { AgencyMembership, Membership, User } from 'types/user'

export interface State {
  id: User['id']
  avatar_url: User['avatar_url']
  first_name: User['first_name']
  last_name: User['last_name']
  email: User['email']
  isClerk: User['isClerk']
  sms_device_number: string | null | undefined
  provisionedNumber: string
  authenticator_url: string | null | undefined
  authUpdated: boolean
  agency_memberships: User['agency_memberships']
}

export const useUserStore = defineStore({
  id: 'user',

  state: (): State => ({
    id: 0,
    avatar_url: '',
    first_name: '',
    last_name: '',
    email: '',
    isClerk: false,
    sms_device_number: '',
    provisionedNumber: '',
    authenticator_url: '',
    authUpdated: false,
    agency_memberships: [],
  }),

  getters: {
    loaded: (state) => !!state.first_name,
    details: ({ id, avatar_url, first_name, last_name, email }) => ({ id, avatar_url, first_name, last_name, email }),
    hasAuthenticator: (state) => !!state.authenticator_url,
    hasSMS: (state) => !!state.sms_device_number,
    fullName: (state) => `${state.first_name} ${state.last_name}`,
    isAdministrator: (state) => state.agency_memberships.some((m) => m.is_administrator),
  },

  actions: {
    async load (): Promise<void> {
      const authenticationStore = useAuthenticationStore()
      const { data } = await api.users.account({})
      this.id = data.User.id
      this.avatar_url = data.User.avatar_url
      this.first_name = data.User.first_name
      this.last_name = data.User.last_name
      this.email = data.User.email
      this.isClerk = data.User.isClerk
      this.agency_memberships = data.User.agency_memberships
      const { agency_memberships: userAgencyMemberships, membership, id } = data.User

      // switch where to load this information from based on user structure
      let programList: Membership[] = []
      let agencyMemberships: AgencyMembership[] = []
      // if no memberships then this is an agent/broker
      const isAgent = !membership?.length
      if (isAgent) {
        const [agencyMembership] = userAgencyMemberships as AgencyMembership[]
        if (agencyMembership) {
          programList = agencyMembership.agency.programs.map((program) => ({
            subscription: agencyMembership.is_issuing_agent ? 'ISSUING_AGENT' : 'BROKER',
            default_program: false,
            program,
          }))
          agencyMemberships = userAgencyMemberships as AgencyMembership[]
        }
      } else {
        programList = membership
      }

      authenticationStore.userId = id
      authenticationStore.programs = programList
      authenticationStore.agencyMemberships = agencyMemberships

      const deviceRes = await api.users.devices({ path: { id: this.id } })
      this.sms_device_number = deviceRes.data.sms_device_number
      this.authenticator_url = deviceRes.data.authenticator_url
    },
    async editDetails (
      data: Pick<UpdateAccountRequest, 'firstName' | 'lastName' | 'email'> & { photo: File | null },
    ): Promise<void> {
      const apiStore = useApiStore()
      const request = UpdateAccountRequest.create({
        id: this.id,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
      })
      if (data.photo) {
        request.avatar = MultipartFormFile.create({
          name: data.photo.name,
          mimeType: data.photo.type,
          content: new Uint8Array(await data.photo.arrayBuffer()),
        })
      }
      const res = await apiStore.user.updateAccount(request)
      if (data.photo && res.avatarUrl) this.avatar_url = res.avatarUrl
      this.first_name = res.firstName
      this.last_name = res.lastName
      this.email = res.email
    },
    async provisionSMS (number?: string): Promise<void> {
      const apiStore = useApiStore()
      if (number) this.provisionedNumber = number
      const request = ProvisionDeviceRequest.create({
        emailAddress: this.email,
        method: { oneofKind: 'phone', phone: { phoneNumber: this.provisionedNumber } },
      })
      await apiStore.user.provisionDevice(request)
    },
    async updateSMS (token: string): Promise<void> {
      const apiStore = useApiStore()
      const request = UpdateDeviceRequest.create({
        emailAddress: this.email,
        token,
        method: { oneofKind: 'phone', phone: {} },
      })
      const response = await apiStore.user.updateDevice(request)
      if (response.method.oneofKind !== 'phone') {
        throw new Error('Unexpected response from server')
      }
      this.sms_device_number = String(response.method.phone.phoneNumber)
      this.provisionedNumber = ''
      this.authUpdated = true
    },
    async clear2fa (device: 'totp' | 'sms'): Promise<void> {
      if (!['totp', 'sms'].includes(device)) {
        throw new Error(`Device type ${device} not allowed.`)
      }
      const apiStore = useApiStore()
      await apiStore.user.deleteDevice(DeleteDeviceRequest.create({
        method: device === 'totp'
          ? { oneofKind: 'totp', totp: {} }
          : { oneofKind: 'phone', phone: {} },
      }))
      if (device === 'totp') this.authenticator_url = ''
      if (device === 'sms') this.sms_device_number = ''
    },
    async provisionQR (): Promise<ProvisionDeviceResponse_Totp> {
      const apiStore = useApiStore()
      const request = ProvisionDeviceRequest.create({
        emailAddress: this.email,
        method: { oneofKind: 'totp', totp: {} },
      })
      const response = await apiStore.user.provisionDevice(request)
      if (response.method.oneofKind !== 'totp') {
        throw new Error('Unexpected response from server')
      }
      return response.method.totp
    },
    async updateQR (token: string): Promise<void> {
      const apiStore = useApiStore()
      const request = UpdateDeviceRequest.create({
        emailAddress: this.email,
        token,
        method: { oneofKind: 'totp', totp: {} },
      })
      await apiStore.user.updateDevice(request)
      // TODO: if the authenticator_url does end up being used somewhere we'll have to hit the devices endpoint to retrieve it again, as for now we just need some value
      this.authenticator_url = 'updated'
      this.authUpdated = true
    },
    /**
     * Attempts to log the user out of the application and invalidate the session with the API.
     * Removes all tokens and authentication data from stores.
     */
    async logout (): Promise<void> {
      try {
        const apiStore = useApiStore()
        await apiStore.user.logout()
      } catch (err) {
        console.error(`Error invalidating session with API. Clearing local data. ${err}`)
      } finally {
        this.$reset()
        const authenticationStore = useAuthenticationStore()
        authenticationStore.$reset()
      }
    },
  },
})

hotReloadStore(useUserStore)
