import { screamingSnakeToCamelCase } from '@policyfly/utils/string'
import { getSelectedOneofValue } from '@protobuf-ts/runtime'
import { computed } from 'vue'

import { useAuthenticationStore } from '@/stores/authentication'
import { useSettingsStore } from '@/stores/settings'
import { useUserStore } from '@/stores/user'

import { roleNameToSettingsSchemaRoleName } from '@/utils/protobuf'

import type { SchemaSettings_RoleName, Permissions, SchemaSettings_Role } from '@policyfly/protobuf'
import type { StatusKind } from '@policyfly/schema/types/schemas/settings'
import type { AppActionStatus } from '@policyfly/schema/types/shared/permissions'
import type { PolicyStatus } from '@policyfly/types/policy'
import type { RoleName } from '@policyfly/types/user'

export type PermissionName = keyof Permissions

export type GeneralPermission =
  | 'newApplicant'
  | 'internalUserListView'

export type UserPermission =
  | 'userAdd'
  | 'userRemove'
  | 'userResetPassword'

export type AgencyPermission =
  | 'agencyListView'
  | 'agencyAdd'
  | 'agencyAddAgent'
  | 'agencyRename'
  | 'agencyManage'
  | 'agencyArchive'
  | 'myAgencyView'

export type AttachmentPermission =
  | 'attachmentRename'
  | 'attachmentArchive'

export type TaskPermission =
  | 'taskAdd'
  | 'taskEdit'

export type AppPermission =
  | 'appQualityControlPass'
  | 'appQualityControlFail'
  | 'appQualityControlSkip'
  | 'appOverride'
  | 'appCancel'
  | 'appAdvancePendingIssue'
  | 'appReclaim'
  | 'appCreateEndorsement'
  | 'appStartOver'
  | 'appResumeCancellation'
  | 'appRecheck'
  | 'appActivatePolicy'
  | 'appApproveEndorsement'
  | 'appEditApplication'
  | 'appDeleteApplication'
  | 'appArchiveApplication'
  | 'appBackToDraft'
  | 'appBackToReviewing'
  | 'appDeclineApplication'
  | 'appDiscardReinstatement'
  | 'appDiscardCancellation'
  | 'appProcessCancellation'
  | 'appAbortCancellation'
  | 'appQuote'
  | 'appEditQuote'
  | 'appQuoteLost'
  | 'appReviewQuotes'
  | 'appIssueBinder'
  | 'appEndAndRestart'
  | 'appIssuePolicy'
  | 'appAddInformation'
  | 'appEditInformation'
  | 'appEditPremium'

export type PolicyPermission =
  | 'polCancel'
  | 'polCreateEndorsement'
  | 'polAbortCancellation'
  | 'polProcessReinstatement'
  | 'polEndorsementCorrectionReport'
  | 'polRenew'
  | 'polReclaim'
  | 'polOfferRenewal'
  | 'polDeclineRenewal'
  | 'polRequestCancellation'
  | 'polStartOver'

export type HomePermission =
  | 'agencyAssignedView'
  | 'homeCoverholderView'
  | 'homeCommentView'

type AppPermissionItem = { status: AppActionStatus, kind: StatusKind }
type PolicyPermissionItem = { status: PolicyStatus }

interface UsePermissionReturnValue<T extends PermissionName> {
  /**
   * Checks if the user has a permission.
   *
   * @param permission The permission to check if the user can access.
   * @param item Details about the item being checked for the permission, if applicable.
   */
  checkPermission<U extends T> (
    permission: U,
    item?: U extends AppPermission
      ? AppPermissionItem
      : U extends PolicyPermission
        ? PolicyPermissionItem
        : never,
  ): boolean
  /**
   * Checks if the user role matches the provided role(s).
   *
   * @param role Either a single role or an array of roles to check against.
   */
  checkRole (role: SchemaSettings_RoleName[] | SchemaSettings_RoleName | undefined): boolean
}

export function usePermission <T extends PermissionName> (): UsePermissionReturnValue<T> {
  const settingsStore = useSettingsStore()
  const authenticationStore = useAuthenticationStore()
  const userStore = useUserStore()

  const isAdministrator = computed(() => {
    return userStore.isAdministrator
  })

  const userRole = computed(() => {
    return (authenticationStore.userRole ?? '').replace('SUPERUSER', 'PROGRAM_ADMIN') as RoleName
  })

  const permissions = computed(() => {
    if (!authenticationStore.userRole || !settingsStore.slug) {
      return {}
    }

    const oneofRole = screamingSnakeToCamelCase(userRole.value) as NonNullable<SchemaSettings_Role['name']['oneofKind']>
    const roleConfig = (() => {
      for (const role of settingsStore.roles) {
        if (role.name.oneofKind === oneofRole) {
          return getSelectedOneofValue(role.name)
        }
      }
    })()
    if (!roleConfig) {
      console.error(`Role ${userRole.value} doesn't exist.`)
      return {}
    }

    const rolePermissions = { ...roleConfig.permissions }
    if (userRole.value === 'BROKER' && isAdministrator.value && 'agencyAdminPermissions' in roleConfig && roleConfig.agencyAdminPermissions) {
      // only assign values that have meaningful overrides, such as `true` or an array with items
      const overridePermissions = Object.fromEntries(
        Object.entries(roleConfig.agencyAdminPermissions as Permissions)
          .filter(([, value]) => value === true || (Array.isArray(value) && value.length)),
      )
      Object.assign(rolePermissions, overridePermissions)
    }

    return rolePermissions
  })

  const checkPermission: UsePermissionReturnValue<T>['checkPermission'] = (permission, item) => {
    const value = permissions.value[permission]
    if (Array.isArray(value)) {
      if (!item) return false
      return value.some((p: { kinds: string[], statuses: string[] } | { statuses: string[] }) => {
        if ('kinds' in p && p.kinds.length) {
          if (!('kind' in item) || !item.kind) return false
          if (!p.kinds.includes(item.kind)) return false
        }
        return p.statuses.includes(item.status)
      })
    } else {
      return !!value
    }
  }

  const checkRole: UsePermissionReturnValue<T>['checkRole'] = (role) => {
    const schemaRole = roleNameToSettingsSchemaRoleName(userRole.value)
    if (!schemaRole) return false
    if (Array.isArray(role)) {
      return !!role.length && role.includes(schemaRole)
    }
    return !!role && role === schemaRole
  }

  return {
    checkPermission,
    checkRole,
  }
}
