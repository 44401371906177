import { request } from '../methods'

export default {
  requestResetPassword: request('post', '/api/v1/users/request_reset_password/'),
  fetch: request('post', '/api/v1/users/fetch/'),
  account: request('get', '/api/v1/users/account/'),
  setupAccount: request('post', '/api/v1/users/setup_account/'),
  resetPassword: request('post', '/api/v1/users/reset_password/'),
  devices: request('get', '/api/v1/users/{id}/devices/'),
  onboard: request('post', '/api/v1/users/onboard/'),
}
